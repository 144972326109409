import "../../assets/scss/contactUs/inforContact.scss";
import {HiOutlineMail} from 'react-icons/hi';
import {FiPhone} from 'react-icons/fi';
import {CiLocationOn} from 'react-icons/ci';
const InforContact = () => {
  return (
    <div className="infor_contact">
      <div className="container">
        <h2 className="title">Contact Us</h2>
        <div className="content_center">
          <img
            loading="lazy"
            src={require("../../assets/images/logo-tma.webp")}
            alt="logo_inno"
            className="logo_tma"
          />
          <ul className="contact-info-gr">
            <li>
              <span>
                <HiOutlineMail className="iconLetter"/>
              </span>
              <div>
                <a href="mailto:contact@tmainnovation.vn">
                  contact@tmainnovation.vn
                </a>
              </div>
            </li>
            <li>
              <span>
                <FiPhone className="iconPhone"/>
              </span>
              <div>(+84) 28-3997-8000 (Ext: 5945)</div>
            </li>
            <li>
              <span>
                <CiLocationOn className="iconLocation"/>
              </span>
              <div>
                TMA Tower, Street #10, Quang Trung Software City, 1 To Ky,
                District 12, Ho Chi Minh City, Vietnam
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default InforContact;
