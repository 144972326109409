import "../../assets/scss/contactUs/sendUs.scss";
import FormContact from "./formContact";
import InforContact from "./inforContact";

const ContactUs = () => {
  return (
    <section className="contact_us" id="contact">
      <FormContact />
      <InforContact />
    </section>
  );
};
export default ContactUs;
