import React from "react";
import "../../assets/scss/screenShots.scss";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper";

export default function ScreenShots() {
  const slide_screenShots = [
    {
      id: 1,
      image: require("../../assets/images/home@3x.webp"),
      title: "Home",
    },
    {
      id: 2,
      image: require("../../assets/images/add-activity-step-1@3x.webp"),
      title: "Select Activity",
    },
    {
      id: 3,
      image: require("../../assets/images/add-activity-step-2@3x.webp"),
      title: "Select Crop and Location",
    },
    {
      id: 4,
      image: require("../../assets/images/add-activity-step-3@3x.webp"),
      title: "Scan Product Code",
    },
    {
      id: 5,
      image: require("../../assets/images/add-activity-step-3-2@3x.webp"),
      title: "Product Information",
    },
    {
      id: 6,
      image: require("../../assets/images/add-activity-step-5@3x.webp"),
      title: "Done",
    },
    {
      id: 7,
      image: require("../../assets/images/report.webp"),
      title: "Report",
    },
    {
      id: 8,
      image: require("../../assets/images/report-chart.webp"),
      title: "Report Chart",
    },
  ];

  return (
    <div className="screenShots_container" id="screen">
      <div className="screenShots_title">Screenshots</div>
      <div className="form_slide">
        <Swiper
          slidesPerView={4}
          centeredSlides={true}
          autoHeight={true}
          spaceBetween={40}
          observer={true}
          observeParents={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          loop={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            600: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 4,
            },
          }}
          modules={[Autoplay, Pagination]}
        >
          {slide_screenShots.map((slide_screenShot) => {
            return (
              <SwiperSlide key={slide_screenShot.id}>
                <div className="form_iamge">
                  <img
                    loading="lazy"
                    src={slide_screenShot.image}
                    alt={slide_screenShot.title}
                    className="slide_image"
                  />
                </div>
                <div className="title_slide">{slide_screenShot.title}</div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}
