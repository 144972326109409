import "../../assets/scss/home/inforHome.scss";
import Agri_SCM from "../../assets/slide/Agri-SCM.pdf";
import { FaCheck } from "react-icons/fa";
import { useRef } from "react";
import useIntersectionObserver from '@react-hook/intersection-observer'

const InforHome = () => {
  const containerRef = useRef()
  const lockRef = useRef(false)
  const { isIntersecting } = useIntersectionObserver(containerRef)
  if (isIntersecting) {
      lockRef.current = true
  }
  return (
    <div className="infor_home">
      <div className="container">
        <div className="content_infor">
          <div className="text_content">
            <h1>A Future For Success Agriculture</h1>
            <ul className="home-benefits">
              <li>
                <FaCheck className="icon_home" /> Easy to collect data from all
                agricultural activities.
              </li>
              <li>
                <FaCheck className="icon_home" /> Step by step guidelines to
                comply with VietGAP, GlobalGAP, GS1, etc
              </li>
              <li>
                <FaCheck className="icon_home" /> Analyze crops and products
                quality.
              </li>
              <li>
                <FaCheck className="icon_home" /> FREE for farmers.
              </li>
            </ul>
            <a href={Agri_SCM} className="btn-green btn-orange scrool">
              Download Slide
            </a>
          </div>
          <div className="video_content" data-aos="zoom-in-up" ref={containerRef}>
            {lockRef.current && (  <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Vl_xXR9wurM"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              className="frame-border wow bounceIn"
               allowfullscreen="allowfullscreen"
              title="video"
              loading="lazy"
            ></iframe> )}
           
          </div>
        </div>
        <div className="content_image">
          <img
            loading="lazy"
            src={require("../../assets/images/Agri-SCM Marketplace.webp")}
            width="100%"
            height="60%"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default InforHome;
