import { useEffect } from "react";
import "./App.css";
import Feature from "./components/Feature/Feature";
import Layout from "./components/layout/layout";
//aos
import AOS from "aos";
import "aos/dist/aos.css";
import ScreenShots from "./components/screenShots/ScreenShots";
import "swiper/css/bundle";
import About_top from "./components/about_top/About_top";
import Home from "./components/home/home";
import FieldInCom from "./components/fieldInCompany/fieldInCompany";
import ContactUs from "./components/contactUs/contactUs";
import LazyLoad from "react-lazyload";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 1000,
    });
    AOS.refresh();
  }, []);
  return (
    <Layout>
      <LazyLoad height={500} once>
        <Home />
      </LazyLoad>
      <LazyLoad height={500} once>
        <Feature />
      </LazyLoad>
      <LazyLoad height={500} once>
        <ScreenShots />
      </LazyLoad>
      <LazyLoad height={500} once>
        <About_top />
      </LazyLoad>
      <LazyLoad height={500} once>
        <FieldInCom />
      </LazyLoad>
      <LazyLoad height={500} once>
        <ContactUs />
      </LazyLoad>
    </Layout>
  );
}

export default App;
