import "../../assets/scss/home/sliderHome.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay } from "swiper";

const SliderHome = () => {
  const images = [
    "slider_01.webp",
    "slider_02.webp",
    "slider_03.webp",
    "slider_04.webp",
  ];

  return (
    <div className="slider_home">
      <Swiper
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        loop={true}
        speed={1500}
        autoplay={{
          delay: 1500,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {images.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <img
                loading="lazy"
                src={require(`../../assets/images/${item}`)}
                alt="img-slider"
                height="auto"
                width="100%"
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
export default SliderHome;
