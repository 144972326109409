import React from "react";
import "../../assets/scss/about_top.scss";
export default function About_top() {
  return (
    <div className="about_top_container" id="about-us">
      <div className="">
        <div className="about_top_title">About Us</div>
        <div className="about_top_box">
          <div className="box_left">
            <div className="box_left_top">
              <img
                loading="lazy"
                className="imgae_logo_abouts"
                src={require("../../assets/images/logo.webp")}
                alt=''
              />
            </div>
            <div className="box_left_bot">
              <a href="https://www.tmainnovation.com/">www.tmainnovation.com</a>
            </div>
          </div>
          <div className="box_right">
            TMA Innovation aims at making technology simple, common and more
            accessible to everyone and every business while maintaining a low
            cost.
          </div>
        </div>
      </div>
    </div>
  );
}
