import "../../assets/scss/contactUs/sendUs.scss";
import data from "../../config/config";
import { useEffect, useState } from "react";
import ApiCaller from "../../api/apiCaller";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";

const FormContact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [comment, setComment] = useState("");

  const [mess, setMess] = useState("");

  //gg recaptcha
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${data.SITE_KEY}`,
      function () {}
    );
  }, []);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let dataForm = {
      name: name,
      email: email,
      phone: phone,
      comment: comment,
    };
    if (dataForm) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(data.SITE_KEY, {
            action: "submit",
          })
          .then(async (reCaptchaToken) => {
            if (reCaptchaToken) {
              let reqData = Object.assign({}, dataForm, {
                "g-recaptcha-response": reCaptchaToken,
              });
              const res = await ApiCaller(
                "modules/contact.php",
                "POST",
                reqData
              );
              if (res && res.status === 200) {
                const id = document.getElementsByClassName("popup_allow");
                id.mess.classList.add("popup_allow_success");
                setMess("Your message has been sent success.");
              } else {
                const id = document.getElementsByClassName("popup_allow");
                id.mess.classList.add("popup_allow_failed");
                setMess("Your message has been sent failed.");
              }
            }
          });
      });
    }

    setName("");
    setEmail("");
    setPhone("");
    setComment("");
  };

  return (
    <div className="send_us">
      <div className="container-fluid">
        <h2 className="title">Send Us a Message!</h2>
        <div className="contact_content">
          <div className="contact_form">
            <form
              method="POST"
              role="form"
              id="contact_form"
              onSubmit={handleOnSubmit}
            >
              <div className="form-group">
                <label htmlFor="name">Your Full Name *</label>
                <input
                  name="name"
                  type="name"
                  className="form-control"
                  placeholder="Type here"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Your Email *</label>
                <input
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  placeholder="Type here"
                  id="email"
                  required={true}
                />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  name="phone"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="form-control"
                  placeholder="Type here"
                  id="phone"
                />
              </div>
              <div className="form-group">
                <label htmlFor="comment">What Can We Help You?</label>
                <textarea
                  className="form-control"
                  id="comment"
                  name="comment"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Type here"
                  rows="4"
                  cols="54"
                ></textarea>
              </div>
              <div id="g-recaptcha"></div>
              <div id="g-recaptcha-error"></div>
              <button
                id="submitButton"
                style={{ margin: "10px 0 0 0" }}
                type="submit"
                className="btn btn_send_message"
              >
                Send Message
              </button>
            </form>

            <div className="popup_allow" id="mess">
              {mess}
            </div>
          </div>
          <div className="contact_map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7836.8302797210135!2d106.631049!3d10.855996!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcf2251bacb86b0f2!2sTMA+Solutions+Lab+6!5e0!3m2!1sen!2sus!4v1560245076116!5m2!1sen!2sus"
              width="100%"
              height="505px"
              title="contact"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FormContact;
