import "../../assets/scss/footer.scss";

const footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center information_footer">
            <p>TMA Innovation © 2020 All Rights Reserved</p>
            <ul className="footer_social">
              <li>
                <a href="https://twitter.com/TicTma">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/TMAInnovation">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/tma-innovation-center/">
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default footer;
