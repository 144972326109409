import "../../assets/scss/home/home.scss";
import SliderHome from "./sliderHome";
import InforHome from "./inforHome";

const Home = () => {
  return (
    <section className="home" id="home">
      <div className="home_overlay"></div>
      <SliderHome />
      <InforHome />
    </section>
  );
};
export default Home;
