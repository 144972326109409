import data from "../config/config";
import axios from "axios";

export default async function ApiCaller(endpoint, method = "GET", body) {
  try {
    let res = await axios({
      method: method,
      url: `${data.API_URL}/${endpoint}`,
      data: body,
    });
    return res;
  } catch (err) {
    console.error(err);
  }
}
