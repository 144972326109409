import React from "react";
import "../../assets/scss/feauture.scss";

export default function Feature() {
  const content_lefts = [
    {
      id: 1,
      icon: "css-features-AnyConvcom__user-fiendly",
      title: "USER FRIENDLY",
      content:
        " Agri-SCM can be used easily without any training required. Anyone can immediately use this solution with our user-friendly interface.",
    },
    {
      id: 2,
      icon: "css-features-AnyConvcom__quick-data-collect",
      title: "QUICK DATA COLLECT",
      content:
        " Agri-SCM provides a way to collect data: voice record, photo &video capture, sensor real-time data collecting, friendlyselect box, etc",
    },
    {
      id: 3,
      icon: "css-features-AnyConvcom__iot_white",
      title: "INTERNET OF THINGS INTEGRATED",
      content:
        " An IoT system was integrated to create a realtime stream input data by sensors. All farming conditions data will be sent automatically to analyze and predict models.",
    },
  ];
  const content_rights = [
    {
      id: 1,
      icon: "css-features-AnyConvcom__smart-insight",
      title: "SMART INSIGHT",
      content:
        "Applying the techniques of Data Science, ArtificialIntelligence and Machine Learning, we are offering a system with smart insight to give users the valuable reports of the farm and the compliances’ status.",
    },
    {
      id: 2,
      icon: "css-features-AnyConvcom__public-api",
      title: "PUBLIC API",
      content:
        "The public APIs are also provided to allow enterprises tointegrate easily as well as use the farming data.",
    },
    {
      id: 3,
      icon: "css-features-free",
      title: "FREE FOR FARMER USERS",
      content:
        "Yes! Agri-SCM is not a free trial. It’s 100% free - simple asthat. Users can experience full features for lifetime withoutany costs. And your free access to Agri-SCM has no time limit. ",
    },
  ];
  return (
    <div id="features" className="feauture_container">
      <div className="container">
        <div className="feauture_title">
          <h2>Main Features</h2>
        </div>
        <div className="feauture_content ">
          <div className="content_left">
            {content_lefts.map((content_left) => {
              return (
                <div className="item_container" key={content_left.id}>
                  <div className="item_left">
                    <div className="title_left">{content_left.title}</div>
                    <p>{content_left.content}</p>
                  </div>
                  <div className={`dropcaps_right `}>
                    <span className={content_left.icon}></span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="content_image" data-aos="zoom-in-up">
            <img
              loading="lazy"
              src={require("../../assets/images/features-iphone.webp")}
              alt="feature main"
              className="image_content"
            />
          </div>
          <div className="content_right">
            {content_rights.map((content_right) => {
              return (
                <div key={content_right.id} className="item_container">
                  <div className="dropcaps_left ">
                    <span className={content_right.icon}></span>
                  </div>
                  <div className="item_left">
                    <div className="title_left">{content_right.title}</div>
                    <p className="">{content_right.content}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
