import "../../assets/scss/fieldInCompany/fieldInCom.scss";

const FieldInCom = () => {
  return (
    <section className="field_company">
      <h5 className="text-center">
        <span className="pink-highlight">300</span>AI, IoT, Big Data, Analytics,
        Blockchain engineers
      </h5>
      <div className="special">
        <div className=" responsive-icon">
          <div className="descbox blue-box h-100">
            <div className="icon">
              <span className="css-features-iot-icon"></span>
              <div>IoT</div>
            </div>
          </div>
        </div>
        <div className=" responsive-icon">
          <div className="descbox pink-box h-100">
            <div className="icon">
              <span className="css-features-datascience-icon"></span>
              <div>Data Science</div>
            </div>
          </div>
        </div>
        <div className=" responsive-icon">
          <div className="descbox light-greenbox h-100">
            <div className="icon">
              <span className="css-features-AI-icon"></span>
              <div>AI/ML</div>
            </div>
          </div>
        </div>
        <div className=" responsive-icon">
          <div className="descbox violet-box h-100">
            <div className="icon">
              <span className="css-features-blockchain-icon"></span>
              <div>Blockchain</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FieldInCom;
