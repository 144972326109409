import React, { useEffect } from "react";
import { Link } from "react-scroll";
import "../../assets/scss/header.scss";
import Gap_app from "../../assets/GAP_App.apk";
import Trace_app from "../../assets/Trace_App.apk";

const Header = (props) => {
  const handleToggleBar = () => {
    props.setShowNav(!props.showNav);
  };

  const navigateHandle = () => {
    props.setShowNav(false);
  };

  const menu = [
    {
      id: 1,

      label: "HOME",
      data_id: "home",
    },

    {
      id: 2,

      label: "FEATURES",
      data_id: "features",
    },
    {
      id: 3,

      label: "SCREENSHORTS",
      data_id: "screen",
    },
    {
      id: 4,

      label: "ABOUT",
      data_id: "about-us",
    },
    {
      id: 5,

      label: "CONTACT",
      data_id: "contact",
    },
  ];

  useEffect(() => {
    let el = document.getElementById("header");
    document.addEventListener("scroll", (e) => {
      let pos = window.scrollY;
      if (pos > 250) {
        el.classList.add("bg_scroll");
      } else {
        el.classList.remove("bg_scroll");
      }
    });
  }, []);

  return (
    <header className="header" id="header">
      <nav className="nav-bar">
        <div className="container nav">
          <Link to="home" activeClass="active" smooth={true} duration={1500}>
            <span className="css-features-AnyConvcom__logo-nav"></span>
          </Link>
          <div
            className={`${props.showNav ? "nav_show" : ""} ${"nav_mobile"} ${
              props.showNav ? "nav_mobile_active" : ""
            } ${"nav_content"}`}
          >
            <span
              className={`${"title_nav_mobile"} ${
                props.showNav ? "title_nav_mobile_active" : ""
              }`}
            >
              Menu
            </span>
            <ul>
              {menu.map((item) => {
                return (
                  <li key={item.id} className={"nav_item"}>
                    <Link
                      onClick={navigateHandle}
                      activeClass={"active"}
                      smooth={true}
                      spyThrottle={500}
                      offset={0}
                      duration={1500}
                      hashSpy={true}
                      spy={true}
                      to={item.data_id}
                      data-to-scrollspy-id={item.data_id}
                      className={"new_tag_a"}
                    >
                      {item.label}
                    </Link>
                  </li>
                );
              })}
              <li className="dropdown">
                <a
                  href="#"
                  className="dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  DOWNLOAD <span className="caret"></span>
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <a href={Gap_app} id="downloadapp-1" download>
                      Farming Activities
                    </a>
                  </li>
                  <li>
                    <a href={Trace_app} id="downloadapp-2" download>
                      Product Traceability
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <input
        className={"menu_check"}
        type="checkbox"
        onClick={handleToggleBar}
        name=""
        id=""
      />
      <div className={"burger"}>
        <span
          className={`${"line"} ${"line1"} ${
            props.showNav ? `${"line1_transform"} ${"line_transform"}` : ""
          }`}
        ></span>
        <span
          className={`${"line"} ${"line2"} ${
            props.showNav ? `${"line2_transform"}` : ""
          }`}
        ></span>
        <span
          className={`${"line"} ${"line3"} ${
            props.showNav ? `${"line3_transform"} ${"line_transform"}` : ""
          }`}
        ></span>
      </div>
    </header>
  );
};

export default Header;
